import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import translationEN from './locales/en/translationEN.json';
import translationMK from './locales/mk/translationMK.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: translationEN

    },
    mk: {
        translation: translationMK

    }
}

i18n
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: resources,
        lng: "mk",
        useSuspense: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;