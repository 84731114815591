export const facebook_footer_icon = <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 0.78125C7.164 0.78125 0 7.94525 0 16.7813C0 25.6173 7.164 32.7813 16 32.7813C24.836 32.7813 32 25.6173 32 16.7813C32 7.94525 24.836 0.78125 16 0.78125ZM20 11.4479H18.2C17.4827 11.4479 17.3333 11.7426 17.3333 12.4853V14.1146H20L19.7213 16.7813H17.3333V26.1146H13.3333V16.7813H10.6667V14.1146H13.3333V11.0373C13.3333 8.67858 14.5747 7.44792 17.372 7.44792H20V11.4479Z" fill="#8B8B8B" />
</svg>


export const instagram_footer_icon = <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.772 9.18392C18.788 9.13858 18.492 9.13058 16 9.13058C13.508 9.13058 13.2133 9.13992 12.2293 9.18392C9.69733 9.29992 8.51867 10.4986 8.40267 13.0106C8.35867 13.9946 8.348 14.2893 8.348 16.7812C8.348 19.2733 8.35867 19.5679 8.40267 20.5533C8.51867 23.0586 9.692 24.2639 12.2293 24.3799C13.212 24.4239 13.508 24.4346 16 24.4346C18.4933 24.4346 18.788 24.4252 19.772 24.3799C22.304 24.2652 23.4813 23.0626 23.5987 20.5533C23.6427 19.5693 23.652 19.2733 23.652 16.7812C23.652 14.2893 23.6427 13.9946 23.5987 13.0106C23.4813 10.4999 22.3013 9.29992 19.772 9.18392ZM16 21.5746C13.3533 21.5746 11.2067 19.4293 11.2067 16.7812C11.2067 14.1346 13.3533 11.9893 16 11.9893C18.6467 11.9893 20.7933 14.1346 20.7933 16.7812C20.7933 19.4279 18.6467 21.5746 16 21.5746ZM20.9827 12.9199C20.364 12.9199 19.8627 12.4186 19.8627 11.7999C19.8627 11.1813 20.364 10.6799 20.9827 10.6799C21.6013 10.6799 22.1027 11.1813 22.1027 11.7999C22.1027 12.4173 21.6013 12.9199 20.9827 12.9199ZM19.1107 16.7812C19.1107 18.4999 17.7173 19.8919 16 19.8919C14.2827 19.8919 12.8893 18.4999 12.8893 16.7812C12.8893 15.0626 14.2827 13.6706 16 13.6706C17.7173 13.6706 19.1107 15.0626 19.1107 16.7812ZM16 0.78125C7.164 0.78125 0 7.94525 0 16.7812C0 25.6173 7.164 32.7812 16 32.7812C24.836 32.7812 32 25.6173 32 16.7812C32 7.94525 24.836 0.78125 16 0.78125ZM25.2773 20.6292C25.124 24.0226 23.2347 25.9026 19.8493 26.0586C18.8533 26.1039 18.5347 26.1146 16 26.1146C13.4653 26.1146 13.148 26.1039 12.152 26.0586C8.76 25.9026 6.87867 24.0199 6.72267 20.6292C6.67733 19.6346 6.66667 19.3159 6.66667 16.7812C6.66667 14.2466 6.67733 13.9293 6.72267 12.9333C6.87867 9.54125 8.76133 7.65992 12.152 7.50525C13.148 7.45858 13.4653 7.44792 16 7.44792C18.5347 7.44792 18.8533 7.45858 19.8493 7.50525C23.2427 7.66125 25.1253 9.54792 25.2773 12.9333C25.3227 13.9293 25.3333 14.2466 25.3333 16.7812C25.3333 19.3159 25.3227 19.6346 25.2773 20.6292Z" fill="#8B8B8B" />
</svg>


export const linkedin_footer_icon = <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 0.78125C7.164 0.78125 0 7.94525 0 16.7812C0 25.6173 7.164 32.7812 16 32.7812C24.836 32.7812 32 25.6173 32 16.7812C32 7.94525 24.836 0.78125 16 0.78125ZM13.3333 22.1146H10.6667V14.1146H13.3333V22.1146ZM12 12.9266C11.1907 12.9266 10.5333 12.2653 10.5333 11.4479C10.5333 10.6319 11.1893 9.96925 12 9.96925C12.8107 9.96925 13.4667 10.6319 13.4667 11.4479C13.4667 12.2653 12.8093 12.9266 12 12.9266ZM22.6667 22.1146H20.0027V18.2999C20.0027 15.7919 17.3333 16.0039 17.3333 18.2999V22.1146H14.6667V14.1146H17.3333V15.5719C18.496 13.4173 22.6667 13.2572 22.6667 17.6359V22.1146Z" fill="#8B8B8B" />
</svg>
